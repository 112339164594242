export const mixin = {
    data: function () {
        return {
            langs: {"en": "English", "de": "Deutsch", "fr": "Français", "es": "Español"}
        }
    },
    methods:{
        translateName(name){
            let lang = this.$i18n.locale
            if(this.langs[lang] && name[lang]){
                return name[lang]
            }
            return name.en
        },
        
        formatPrice(amount){
            var locale = "en";
            amount = parseFloat(amount);
            switch(locale){
                case "en":
                case "fr":
                    return Math.abs(amount).toFixed(2).replace(".", ",") +"€";
            }
        },
    }
}