<template>
    <div class="header">
        <div class="container-fluid">
            <nav class="navbar navbar-header sticky-top navbar-expand-lg navbar-dark">
                <div class="container-fluid pt-2 pb-2">
                    <a class="logo" :href="'/'">
                        <img ref="logo" :src="logoImage"/>
                    </a>
                    <button class="navbar-toggler custom-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarText" aria-expanded="false">
                        <div class="shapeshifter-menu play-menu menu" id="menu"></div>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarText">
                        <ul class="nav navbar-nav ml-auto mr-auto">
                            <li class="nav-item active">
                                <a class="nav-link" :href="base_url + 'product/add'">{{$t("headerAddProduct")}}</a>
                            </li>
                            <li class="nav-item active">
                                <a class="nav-link" :href="base_url + 'product/edit'">{{$t("headerEditProduct")}}</a>
                            </li>
                            <li class="nav-item active">
                                <a class="nav-link" :href="base_url + 'category/add'">{{$t("headerAddCategory")}}</a>
                            </li>
                            <li class="nav-item active">
                                <a class="nav-link" :href="base_url + 'category/edit'">{{$t("headerEditCategory")}}</a>
                            </li>
                            <li class="nav-item active">
                                <a class="nav-link" :href="base_url + 'import-csv'">{{$t("headerImportCsv")}}</a>
                            </li>
                            <li class="nav-item active">
                                <a class="nav-link" :href="base_url + 'category/list'">{{$t("headerListAllCategories")}}</a>
                            </li>
                            <!-- <li class="nav-item active">
                                <a class="nav-link" :href="base_url + 'logo/add'">{{$t("headerAddLogo")}}</a>
                            </li>
                            <li class="nav-item active">
                                <a class="nav-link" :href="base_url + 'logo/edit'">{{$t("headerEditLogo")}}</a>
                            </li> -->
                            <li class="nav-item active">
                                <div :class="{'searchBar' :!showSearchInput}" class="shapeInput">
                                    <b-input-group size="sm">
                                        <b-input-group-prepend  is-text>
                                            <b-icon icon="search" @click="toggleSearchInput" scale="1" class="my-2 ml-2" :class="{'mr-3':showSearchInput}" variant="dark"></b-icon>
                                        </b-input-group-prepend>                      
                                        <b-form-input v-model="searchInput" v-if="showSearchInput" ref="searchInput"  type="search"  class="border-0" placeholder="Search terms" autofocus></b-form-input>
                                        <b-icon v-if="showSearchInput" icon="x" @click="disableSearch" scale="2" class="mt-2 pt-1 mr-2" variant="dark"></b-icon>
                                    </b-input-group>
                                </div>
                                <SearchResults v-show="searchKeyterm.length > 2" :keyterm="searchKeyterm"  />
                            </li>  
                            <li class="nav-item active">
                                <a class="nav-link pt-1" :href="cat_to_pdf"><img src="@/assets/img/fa-print.png" alt="fa-print" class="m-0" style="width: 32px; height: 32px"></a>
                            </li>
                            <li class="nav-item active">
                                <a class="nav-link pt-1" href="https://weshop-catalog-wldd-tools.apps.dev.caas4noprd.worldline-solutions.com/"><img src="@/assets/img/fa-print-v2.png" alt="fa-print-v2" class="m-0" style="width: 32px; height: 32px"></a>
                            </li>
                            <li class="nav-item active">
                                <b-dropdown :text="currentLocaleLabel" toggle-tag="a" variant="primary" class="lang-dropdown">
                                    <b-dropdown-item v-for="locale in locales" :key="locale.id" @click="changeLocale(locale.shortLocale)">{{locale.label}}</b-dropdown-item>
                                </b-dropdown>
                            </li>
                        </ul>
                                        
                    </div>
                </div>
            </nav>
        </div>
    </div>
</template>

<script>
var utils = require('@/assets/js/utils');
import { mapActions, mapState } from 'vuex'
import SearchResults from "@/components/SearchResults";

export default {
    name : 'Header',
    components: {
    SearchResults,
  },
    data: function() {
        return{
            logoImage: require ("@/assets/img/logo.png"),
            base_url: this.$route ? "/" : "",
            cat_to_pdf: utils.getConfig().URL_CAT_PDF,
            locales: this.getAvailableLocales(),
            searchInput: "",
            showSearchInput: false
        }
    },
    methods:{
        ...mapActions(['setSearchKeyterm']),
        getAvailableLocales(){
            return this.$i18n.availableLocales.map( (locale, id) => {return {id, shortLocale: locale, label: this.$t(`locales.${locale}`)}})
        },
        changeLocale(locale){
            this.$i18n.locale = locale
            localStorage.setItem("lang", locale)
        },
        disableSearch(){
            this.searchInput = ""
            this.showSearchInput = false
        },
        toggleSearchInput() {
          this.showSearchInput = true;
          this.$refs.searchInput.focus();
        }
    },
    computed:{
        ...mapState(['searchKeyterm']),
        currentLocaleLabel(){
            if(this.locales){
                return this.locales.filter(locale => locale.shortLocale === this.$i18n.locale)[0].label
            }
            return this.$t("lang")
        }
    },
    watch:{
        searchInput(val){
            this.setSearchKeyterm(val)
        }
    }
}
</script>
<style>
li{
    cursor: pointer;
}
.searchBar{
    width: 35px;
    height: 35px;
}
.shapeInput{
    border-radius: 30px;
    background-color: white;
}
</style>