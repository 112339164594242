<template>
  <div class="app">
    <Header/>
    <router-view />
  </div>
</template>

<script>
import Header from './components/Header.vue';

export default {
  name: 'App',
  components: {
    Header,
  },
  created(){
    this.$i18n.locale = localStorage.getItem("lang") || "en"
  },
}
</script>

<style lang="scss">
  @import "./assets/css/all.css";
  @import "./assets/css/success.css";
  @import "./assets/css/menu.css";
  @import "./assets/css/custom.scss";
  @import "../node_modules/bootstrap/scss/bootstrap.scss";
</style>

