import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    searchKeyterm: ""
  },
  mutations: {
    SET_SEARCH_KEYTERM(state, keyterm){
      console.log(keyterm)
      state.searchKeyterm = keyterm
  }
  },
  actions: {
    setSearchKeyterm({commit}, keyterm){
      commit('SET_SEARCH_KEYTERM', keyterm)
  }
  },
  modules: {
  }
})
