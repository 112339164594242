<template>
    <div class="search-results" v-if="searchResults" >
        <div v-if="searchResults.length === 0" class="noResult">No Result Founds</div>
        <div v-else>
            <div @click="isFilter(result.id)" v-for="result in searchResults" :key="result.id" class="search-card bv-example-row">
                <b-row >

                    <b-col cols="3" md="2">
                        <img :src="productImgUrl(result.image)" class="product-image">
                    </b-col>

                    <b-col cols="6" md="10" class="d-flex flex-column">
                        <b-row class="align-center">
                            <b-col cols="12" class="product-name">{{translateName(result.name) | capitalizeFirstLetter}}</b-col>
                        </b-row>
                        <b-row class="align-center">
                            <b-col cols="6" class="product-price">{{formatPrice(result.price)}}</b-col>  
                        </b-row>
                    </b-col>

                </b-row>
            </div>
        </div>
    </div>
</template>
<script>
const CatalogServices = require("@/services/CatalogServices");
import {getConfig} from '@/assets/js/utils.js';

export default {
    name: 'SearchResults',
    props : ['keyterm'],
    data () {
        return {
            searchResults: null,
            selected: null,
            base_url: this.$route ? "/" : "",
        }
    },
    methods: {
        productImgUrl(image){
            return getConfig().WS_URL + "/catalog/product/image/" + image;
        },
        isFilter(productId){
            window.location.replace(window.location.protocol + "//" + window.location.host + "/product/edit/" + productId);
            
        }
    },
    watch:{
        async keyterm(){
            let {data, status} = await CatalogServices.searchProducts(this.keyterm)
            if(status === 200){
                this.searchResults = data
            }
        },
    }
}
</script>
<style scoped>
.search-results {
    background: white;
    min-height: 300px;
    max-height: 300px;
    width: 30%;
    position: absolute;
    top: 59px;
    left: 950px;
    z-index: 100;
    border: 1px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    overflow: auto;
}

.search-card {
    padding: 5px;
    position: relative;
    box-sizing: border-box;
    background: #fff;
    border-radius: 2px;
    margin: 16px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    flex-direction: row;
    transition: 2s ease-in-out;
}

.product-name {
    color: black;
    font-size: 15px;
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.product-price {
     color: black;
     font-weight: bold;
     font-size: 24px;
 }

.product-image{
    width: 100%;
}

.product-qty{
    width: 23px;
    text-align: center;
}

@media screen and (max-width: 768px) {
    .product-price {
        font-size: 18px;
    }
}
</style>