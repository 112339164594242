var config = {
    URL:"https://weshop-ws-v2-weshop.pubqlf.co.as8677.net/",
    URL_INSERT:"https://weshop-insert-weshop.pubqlf.co.as8677.net/",
    URL_OTHER:"https://weshop-ws-v2-weshop.pubqlf.co.as8677.net/",
    URL_CAT_PDF:"https://catalog-to-pdf-wldd-tools.pubqlf.co.as8677.net/",
    CLIENT_ID: "0b11518ee992ab6cb22db03112b06149eb3ba6ab",
    CLIENT_CALLBACK: 'https://weshop-ws-v2-weshop.pubqlf.co.as8677.net/admin/token/WESHOP_INSERT',
    OAUTH2_SERVER: 'https://dasso.kzn.as8677.net/',
    WS_URL:`https://weshop-ws-v2-weshop.pubqlf.co.as8677.net`,
};
var useEANCache = false;
var clickedEAN = false;

export function getConfig(){
    return config;
}

export function isNotNull(toCheck){
    if(toCheck != null && toCheck != "" && toCheck != "undefined" && toCheck != "-1"){
        return true;
    }
    else{
        return false;
    }
}

export function generateEan(ean){
    if(clickedEAN != true) {
        $("#ean").prop("disabled", true);
        $("#barcode").attr("hidden", false)
        let path = "cachemanagement/versions/getEAN";
        useEANCache = true;
        var ean12 = parseInt(Math.random()*Math.pow(10,12))
        var ean13 = ean13ValidityKey(ean12)
        ean = ean13;
        JsBarcode("#barcode", ean13, {format: "EAN13"});
        clickedEAN = true;
        return ean;
    }
    else {
        $("#ean").prop( "disabled", false);
        $("#barcode").attr("hidden", true)
        ean = "";
        useEANCache = false;
        clickedEAN = false;
        return ean;
    }
}

export function generateEanForCsv(ean){
    if(clickedEAN != true) {
        let path = "cachemanagement/versions/getEAN";
        useEANCache = true;
        var ean12 = parseInt(Math.random()*Math.pow(10,12))
        var ean13 = ean13ValidityKey(ean12)
        ean = ean13;
        clickedEAN = true;
        return ean;
    }
    else {
        ean = "";
        useEANCache = false;
        clickedEAN = false;
        return ean;
    }
}

function ean13ValidityKey(ean12){
    var checkSum = 0;
    let ean12str = "0" + ean12.toString();
    var arr = ean12str.substr(ean12str.length - 12, ean12str.length).split("").reverse();
    var i = 0, p = 0;

    for (var index=0; index<arr.length; index++) {
    if (index % 2 == 0) {
        p += Number(arr[index]);
    }
    else{
        i += Number(arr[index]);
    }
    }
    var n = i + (3 * p)
    var arrondi10Sup = n;

    while((arrondi10Sup % 10) != 0){
        arrondi10Sup++;
    }

    checkSum = arrondi10Sup - n;

    return ean12 * 10 + checkSum;
}



