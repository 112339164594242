import Vue from 'vue'
import axios from 'axios'
import App from './App.vue'
import router from '@/router'
import VueResource from 'vue-resource'
import Vuelidate from 'vuelidate';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import i18n from './i18n'
import store from './store'
import {mixin} from "./mixins/mixin"

Vue.config.productionTip = false
Vue.prototype.$http = axios
Vue.use(VueResource)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(Loading)
Vue.use(Vuelidate);
Vue.mixin(mixin)


var utils = require('@/assets/js/utils');
var config = utils.getConfig();

router.beforeEach((to, from, next) => {
  dassoLogin(to, next);
})

import filters from '@/filters/filter';

let filterNames = Object.keys(filters);
filterNames.forEach(filterName => {
  Vue.filter(filterName, filters[filterName]);
});

function configAxios(){
  axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
  axios.defaults.headers.common['ENV'] = 'WESHOP_INSERT';
  var token = localStorage.getItem("WESHOP_INSERT_token");

  if(utils.isNotNull(token)) {
    axios.defaults.headers.common['Authorization'] = "Bearer " + token;
  }
}
Vue.filter('truncate', function (text, length, suffix) {
  if(text){
    if (text.length > length) {
      return text.substring(0, length) + suffix;
    } else {
        return text;
    }
  }
  return null
});


function dassoLogin(to, next){
  if(true){
    localStorage.setItem("WESHOP_INSERT_token", process.env.VUE_APP_ADMIN_TOKEN);
    localStorage.setItem("WESHOP_INSERT_das", process.env.VUE_APP_ADMIN_DAS);
    localStorage.setItem("WESHOP_INSERT_name", process.env.VUE_APP_ADMIN_NAME);
    localStorage.setItem("WESHOP_INSERT_email", process.env.VUE_APP_ADMIN_EMAIL);
  }

  var token = localStorage.getItem("WESHOP_INSERT_token");
  var isLogout = to.query.logout;
  var adminComplexId = to.query.adminComplexId;
  var needDassoLogin = false;

  configAxios();

  if(utils.isNotNull(token)){
    axios.get(config.URL + "version")
    .then(function (response) {
      if (response.status == 401) {
        localStorage.removeItem("WESHOP_INSERT_token");
        needDassoLogin = true;
      }
    })
  }
  else if(utils.isNotNull(adminComplexId)){
    localStorage.removeItem("WESHOP_INSERT_token");

    var params = new URLSearchParams();
    params.append('adminComplexId', adminComplexId);
    axios.post(config.URL + "admin/authenticate", params)
    .then(function (response) {
      if (response.status == 200) {
        localStorage.setItem("WESHOP_INSERT_token", response.data.token);
        localStorage.setItem("WESHOP_INSERT_das", response.data.das);
        localStorage.setItem("WESHOP_INSERT_name", response.data.name);
        localStorage.setItem("WESHOP_INSERT_email", response.data.email);
        next({path: to.path});
      }
      else{
        alert("Error on get token");
      }
    })
  }
  else if(utils.isNotNull(isLogout) && isLogout == "true"){
    var clientId = config.CLIENT_ID;
    var redirectUri = encodeURI(config.CLIENT_CALLBACK);
    var dassoLogin = config.OAUTH2_SERVER + "authorize.php?client_id=" + clientId + "&response_type=code&redirect_uri=" + redirectUri;
    window.location.replace(dassoLogin);
  }
  else{
    needDassoLogin = true;
  }

  if(needDassoLogin){
    dassoLogout();
  }
  else{
    next();
  }
}

function dassoLogout(){
  var dassoLogout = config.OAUTH2_SERVER + "login.php?action=logout&redirect=" + encodeURI(window.location.href.split("?")[0]+ "?logout=true");
  window.location.replace(dassoLogout);
}

new Vue({
  router,
  el: "#app",
  i18n,
  store,
  render: h => h(App)
});
