const axios = require('axios');
import {getConfig} from '@/assets/js/utils.js';


export function searchProducts(keyterm) {
    let path = '/search/?keyterm=' + keyterm
    console.log(getConfig().WS_URL);
    return axios.get(getConfig().WS_URL + path)
}

// export function getInfosProductOff(productEan){
//     let path = `https://world.openfoodfacts.org/api/v0/product/${productEan}.json`
//     // let path = `https://animechan.vercel.app/api/random`
//     return axios.get(path)
    
// }
// let {data, status} = await axios.get(path)
// if(status === 200 && data && data.product){
//     return(data.product)
// }
// return null