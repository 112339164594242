import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router);

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: "index",
            component: () => import('@/components/Home.vue')
        },
        {
            path: '/product/add',
            name: "addProduct",
            component: () => import('@/components/product/AddProduct.vue')
        },
        {
            path: '/import-csv',
            name: "importCSV",
            component: () => import('@/components/csv/ImportCsv.vue')
        },
        {
            path: '/product/edit',
            name: "editProduct",
            component: () => import('@/components/product/EditProduct.vue')
        },
        {
            path: '/product/edit/:id',
            name: "editProduct_id",
            component: () => import('@/components/product/EditProduct.vue')
        },
        {
            path: '/category/add',
            name: "addCategory",
            component: () => import('@/components/category/AddCategory.vue')
        },
        {
            path: '/category/edit',
            name: "editCategory",
            component: () => import('@/components/category/EditCategory.vue')
        },
        {
            path: '/category/edit/:id',
            name: "editCategory_id",
            component: () => import('@/components/category/EditCategory.vue')
        },
        {
            path: '/category/list',
            name: "listCategory",
            component: () => import('@/components/category/list/ListCategory.vue')
        },
        {
            path: '/category/list/child/:id',
            name: "listCategoryChild",
            component: () => import('@/components/category/list/ListCategoryChild.vue')
        },
        {
            path: '/category/list/child/product/:id',
            name: "listCategoryProduct",
            component: () => import('@/components/category/list/ListCategoryProduct.vue')
        },
        {
            path: '/logo/add',
            name: "addLogo",
            component: () => import('@/components/logo/AddLogo.vue')
        },
        {
            path: '/logo/edit',
            name: "editLogo",
            component: () => import('@/components/logo/EditLogo.vue')
        },
        {
            path: '/success/:id',
            name: "success_id",
            component: () => import('@/components/Success.vue')
        },
        {
            path: '/success',
            name: "success",
            component: () => import('@/components/Success.vue')
        },
    ]
});